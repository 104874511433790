import React, { Component } from 'react'
import profpic from '../images/ProfPic.jpg'
import bandpic from '../images/Band.jpg'
import screenshot1 from '../images/B&TScreenshot1.png'
import screenshot2 from '../images/B&TScreenshot2.png'
import screenshot3 from '../images/B&TScreenshot3.png'
import screenshot4 from '../images/B&TScreenshot4.png'
import screenshot5 from '../images/B&TScreenshot5.png'
import pumpkin from '../images/Pumpkin.png'
import verkada from '../images/verkada-logo-vector.png'
import yonder from '../images/yonderrover.jpg'
import minecraft from '../images/Minecraft.png'
import dummy3 from '../images/dummy3.png'
import dummy4 from '../images/dummy4.png'
import dummy5 from '../images/dummy5.png'
import climb from '../images/Climbing.png'
import toto from '../images/toto.jpg'
import nara from '../images/nara.jpeg'
import origami1 from '../images/Origami/origami1.png'
import origami2 from '../images/Origami/origami2.JPG'
import origami3 from '../images/Origami/origami3.JPG'
import origami4 from '../images/Origami/origami4.JPG'
import origami5 from '../images/Origami/origami5.JPG'
import origami6 from '../images/Origami/origami6.JPG'
import origami7 from '../images/Origami/origami7.JPG'
import origami8 from '../images/Origami/origami8.JPG'
import origami9 from '../images/Origami/origami9.JPG'
import origami10 from '../images/Origami/origami10.JPG'
import origami11 from '../images/Origami/origami11.JPG'
import origami12 from '../images/Origami/origami12.JPG'
import origami13 from '../images/Origami/origami13.JPG'
import origami14 from '../images/Origami/origami14.JPG'
import origami15 from '../images/Origami/origami15.JPG'
import origami16 from '../images/Origami/origami16.JPG'
import origami17 from '../images/Origami/origami17.JPG'
import origami18 from '../images/Origami/origami18.JPG'
import meme from '../images/meme.jpg'
import vimmeme from '../images/vimmeme.png'
import { Parallax } from "react-parallax";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import scrollToComponent from 'react-scroll-to-component';
import { Element, scroller, Link, animateScroll as scroll } from "react-scroll";
import Grid from '@material-ui/core/Grid';
import { Carousel } from 'react-responsive-carousel';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    borderColor: 'white',
  },
  buttonFocused: {
    margin: theme.spacing(1),
    color: 'white',
    borderColor: 'white',
    boxShadow: '0 0 0 0.2rem rgba(255,255,255,.25)',
  },
  input: {
    display: 'none',
  },
}));

export default class Profpage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    scroll.scrollToTop(); 
  }

  componentDidUpdate() {
    scroll.scrollToTop(); 
  }

  scrollToComp(to) {
    scroller.scrollTo(to, {
      duration: 500,
      smooth: true,
      offset: -50
    })
  }

  render() {
    return(
      <div>
				<Head scroller={this.scrollToComp}/>
        <SkillsFR/>
				<BG bg={yonder} height={400}/>
        <Element name='aboutme'>
          <AboutMe/>
        </Element>
				<BG bg={minecraft} height={600}/>
        <Origami/>
				<BG bg={toto} height={400}/>
        <Memes/>
				<BG bg={climb} height={800}/>
        <Footer scroller={scroll.scrollToTop}/>
      </div>
    );
  }
}

function Head(props) {
  const classes = useStyles();

  return(
    <Parallax bgImage={bandpic} strength={500} renderLayer={percentage => (
      <div>
        <div
        style={{
          position: "absolute",
            background: `rgba(0, 0, 0, ${(1 - Math.sqrt(percentage)) * 2.5})`,
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(-50%,-50%)",
            width: 10000,
            height: 10000
        }}
        />
      </div>
    )} >
      <div style={{height: 1500}}>
        <div style={{height: 300}}>
        </div>
        <div>
          <img src={profpic} alt="profpic" class="rounded-circle img-fluid bordered z-depth-5" style={{width: 250, height:250}}>
          </img>
          <div style={{height: 11}}/>
          <section className='info-container'>
            <h1 style={{color: "white"}}>Davin Tjong</h1>
            <h4 style={{color: "white"}}>UCSD CSE '22</h4>
          </section>
          <hr style={{color: "white", backgroundColor: "white", width: 100}}/>

          <div>
            <Button variant="outlined" className={classes.button} onClick={() => props.scroller('skills')} href="#">
              Skills
            </Button>

            <Button variant="outlined" className={classes.button} onClick={() => props.scroller('aboutme')} href="#">
              About Me
            </Button>
          </div>
        </div>
      </div>
    </Parallax>
  )
}

const AboutMe = () => (
	<div class="elegant-color-dark z-depth-5">
		<div style={{height: 50}}/>
		<section className='info-container'>
			<h1 style={{color: "white"}}>About Me</h1>
			<hr style={{color: "white", backgroundColor: "white", width: '80vw'}}/>
			<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
				<h3 style={{color: "white", width: "80vw"}}>
				I am studying Computer Science at UC San Diego.
				</h3>
			</div>
			<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
				<h3 style={{color: "white", width: "80vw"}}>
				-Vim enthusiast-
				</h3>
			</div>
			<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
				<h3 style={{color: "white", width: "80vw"}}>
					Most of the relevant things are on my resume, so I'm going to fill the rest of this website with other stuff. I made this website to learn react anyway.
				</h3>
			</div>
		</section>
		<div style={{height: 50}}/>
	</div>
);

const Memes = () => (
	<div class="elegant-color-dark z-depth-5">
		<div style={{height: 50}}/>
		<section className='info-container'>
      <h3 style={{color: "white"}}>
↑ my dog ↑
      </h3>
      <h3 style={{color: "white"}}>
  <a href="https://soundcloud.com/davin-tjong/gusty-garden-galaxy-super-mario-galaxy">
Me playing Mario music with a lot of tearing
  </a>
      </h3>

      <h3 style={{color: "white"}}>
  this a meme I made
      </h3>
  <img src={meme} style={{height:300}}/>
      <h3 style={{color: "white"}}>
another one
      </h3>
  <img src={vimmeme} style={{height:300}}/>

      <h3 style={{color: "white"}}>
  <a href="https://www.facebook.com/davin.tjong/videos/2239297593018864/">
oh I love this one
  </a>
      </h3>
      <h3 style={{color: "white"}}>
 ↓ totally not staged to make me look like a better climber than I am ↓ 
      </h3>
		</section>
		<div style={{height: 50}}/>
	</div>
);

const Footer = (props) => {
  if(isMobile) {
    return(
	<div class="elegant-color-dark z-depth-5" style={{height: 70}}>
		<div style={{height: 15}}/>
		<section className='info-container'>
        <Grid container spacing={3} style={{width:'100vw'}}>
        <Grid item xs={4}>
			<a class="nav-link" style={{color: 'white', float: 'center'}} onClick={() => props.scroller()} href="#">
				Back to Top
			</a>
        </Grid>
        <Grid item xs={8}>
			<div style={{color:'white', textAlign: 'right', paddingTop: 6}}>
				Updated 1/29/20
			</div>
        </Grid>
      </Grid>
		</section>
	</div>
    );
  }
  return(
	<div class="elegant-color-dark z-depth-5" style={{height: 70}}>
		<div style={{height: 15}}/>
		<section className='info-container'>
<Grid container spacing={3} style={{width:'100vw'}}>
        <Grid item xs={4}>
			<div style={{color:'white', float: 'left', paddingLeft: 30, paddingTop: 6}}>
				Created by Davin Tjong
			</div>
        </Grid>
        <Grid item xs={4}>
			<a class="nav-link" style={{color: 'white', float: 'center'}} onClick={() => props.scroller()} href="#">
				Back to Top
			</a>
        </Grid>
        <Grid item xs={4}>
			<div style={{color:'white', textAlign: 'right', paddingTop: 6}}>
				Updated 1/21/20
			</div>
        </Grid>
      </Grid>
		</section>
	</div>
  );
}

const Skills = () => (
	<div class="elegant-color-dark z-depth-5">
		<div style={{height: 50}}/>
			<h1 style={{color: "white"}}>Skills</h1>
      <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
				<h3 style={{color: "white"}}>
N/A
				</h3>
		<div style={{height: 50}}/>
	</div>
);

const SkillsFR = () => (
	<div class="elegant-color-dark z-depth-5">
		<div style={{height: 50}}/>
			<h1 style={{color: "white"}}>Experience</h1>
      <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
			<h3 style={{color: "white"}}>
				Here's my <a href = "https://drive.google.com/file/d/1aqJaE7EDzojcqzjMQIclMOVS3UnmBoi8/view?usp=sharing" download target = "_blank"> resume.</a>
			</h3>
			<h3 style={{color: "white"}}>
				My <a href = "https://github.com/dtjong" download target = "_blank"> github</a> and <a href = "https://gitlab.com/dtjong1" download target = "_blank"> github.</a>
			</h3>
    
		<div style={{height: 50}}/>
	</div>
);

const Origami = () => (
	<div class="elegant-color-dark z-depth-5">
		<div style={{height: 50}}/>
		<h3 style={{color: "white"}}>
			^ minecraft pagoda I made 
		</h3>
		<h1 style={{color: "white"}}>Origami</h1>
		<hr style={{color: "white", backgroundColor: "white", width: 100}}/>
		<h3 style={{color: "white"}}>
			I like folding origami, check some of it out.
		</h3>
		<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
			<div style={{width:"60vw"}}>
				<Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
					<div>
						<img src={origami1}/>
					</div>
					<div>
						<img src={origami2}/>
					</div>
					<div>
						<img src={origami3}/>
					</div>
					<div>
						<img src={origami4}/>
					</div>
					<div>
						<img src={origami5}/>
					</div>
					<div>
						<img src={origami6}/>
					</div>
					<div>
						<img src={origami7}/>
					</div>
					<div>
						<img src={origami8}/>
					</div>
					<div>
						<img src={origami9}/>
					</div>
					<div>
						<img src={origami10}/>
					</div>
					<div>
						<img src={origami11}/>
					</div>
					<div>
						<img src={origami12}/>
					</div>
					<div>
						<img src={origami13}/>
					</div>
					<div>
						<img src={origami14}/>
					</div>
					<div>
						<img src={origami15}/>
					</div>
					<div>
						<img src={origami16}/>
					</div>
					<div>
						<img src={origami17}/>
					</div>
					<div>
						<img src={origami18}/>
					</div>
				</Carousel>
			</div>
		</div>
		<div style={{height: 50}}/>
	</div>
);

const BG = (props) => (
	<Parallax bgImage={props.bg} strength={500} renderLayer={percentage => (
		<div>
			<div
			style={{
				position: "absolute",
					background: `rgba(0, 0, 0, ${(1 - percentage * 1.5)})`,
					left: "50%",
					top: "50%",
					borderRadius: "50%",
					transform: "translate(-50%,-50%)",
					width: 10000,
					height: 10000
			}}
			/>
		</div>
	)} >
		<div style={{height: props.height}}>
		</div>
	</Parallax>
);


