import React, { Component } from 'react'
import btlogo from '../images/B&TIcon.png'
import screenshot1 from '../images/B&TScreenshot1.png'
import screenshot2 from '../images/B&TScreenshot2.png'
import screenshot3 from '../images/B&TScreenshot3.png'
import screenshot4 from '../images/B&TScreenshot4.png'
import screenshot5 from '../images/B&TScreenshot5.png'
import screenshot6 from '../images/B&TScreenshot6.png'
import screenshot7 from '../images/B&TScreenshot7.png'
import screenshot8 from '../images/B&TScreenshot8.png'
import { Parallax } from "react-parallax";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import scrollToComponent from 'react-scroll-to-component';
import { Element, scroller, Link, animateScroll as scroll } from "react-scroll";
import { Carousel } from 'react-responsive-carousel';
import { withStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    borderColor: 'white',
  },
  buttonFocused: {
    margin: theme.spacing(1),
    color: 'white',
    borderColor: 'white',
    boxShadow: '0 0 0 0.2rem rgba(255,255,255,.25)',
  },
  input: {
    display: 'none',
  },
}));

export default class Gamepage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.scrollToTop(); 
  }

  componentDidUpdate() {
    this.scrollToTop(); 
  }

  scrollToTop() {
    scroll.scrollToTop({
      duration: 500
    }); 
  }

  scrollToComp(to) {
    scroller.scrollTo(to, {
      duration: 500,
      smooth: true,
      offset: -50
    })
  }

  render() {
    return(
      <div>
				<Head scroller={this.scrollToComp}/>
        {/*
        <Element name='downloads'>
          <Downloads/>
        </Element>
				<BG bg={screenshot6} height={600}/>
        */ }
        <Element name='about'>
          <About/>
        </Element>
				<BG bg={screenshot3} height={800}/>
        <Element name='progress'>
          <Progress/>
        </Element>
				<BG bg={screenshot7} height={800}/>
        <Element name='help'>
					<Help/>
        </Element>
				<BG bg={screenshot8} height={800}/>
        <Footer scroller={this.scrollToTop}/>
      </div>
    );
  }
}

function Head(props) {
  const classes = useStyles();

  return(
    <Parallax bgImage={screenshot4} strength={500} renderLayer={percentage => (
      <div>
        <div
        style={{
          position: "absolute",
            background: `rgba(0, 0, 0, ${(1 - Math.sqrt(percentage)) * 2.5})`,
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(-50%,-50%)",
            width: 10000,
            height: 10000
        }}
        />
      </div>
    )} >
      <div style={{height: 1500}}>
        <div style={{height: '25vh'}}>
        </div>
        <div>
          <img src={btlogo} alt="Blade&Tome logo" class="rounded-circle img-fluid" style={{width: 250, height:250}}>
          </img>
          <div style={{height: 11}}/>
          <section className='info-container'>
            <h1 style={{color: "white"}}>Syfte's Quest</h1>
          </section>
          <hr style={{color: "white", backgroundColor: "white", width: 100}}/>

          <div>
            <Button variant="outlined" className={classes.button} onClick={() => props.scroller('downloads')} href="#">
              Downloads
            </Button>

            <Button variant="outlined" className={classes.button} onClick={() => props.scroller('about')} href="#">
              About
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => props.scroller('progress')} href="#">
              Progress
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => props.scroller('help')} href="#">
              Help
            </Button>
          </div>
        </div>
      </div>
    </Parallax>
  )
}

function Downloads() {
  const classes = useStyles();

  if(isMobile) {
    return(
      <div class="elegant-color-dark z-depth-5">
        <div style={{height: 50}}/>
        <section className='info-container'>
          <h1 style={{color: "white"}}>Downloads (alpha)</h1>
          <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
          <Button variant="outlined" className={classes.button}>
            Not on mobile :( (sorry)
          </Button>

          {/*
        <Button href="https://github.com/dtjong/BladeAndTome/releases/download/v0.0.2-alpha/BladeAndTome.app.zip" variant="outlined" className={classes.button}>
          Blade and Tome (Windows)
        </Button>
        */}
        </section>
        <div style={{height: 50}}/>
      </div>
            );
  }

  return(
    <div class="elegant-color-dark z-depth-5">
      <div style={{height: 50}}/>
      <section className='info-container'>
        <h1 style={{color: "white"}}>Downloads (alpha)</h1>
        <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
        <Button href="https://github.com/dtjong/SyftesQuest/releases/download/v0.0.3-alpha/SyftesQuest.app.zip" variant="outlined" className={classes.button}>
          Syfte's Quest (Mac)
        </Button>

    {/*
        <Button href="https://github.com/dtjong/BladeAndTome/releases/download/v0.0.2-alpha/BladeAndTome.app.zip" variant="outlined" className={classes.button}>
          Blade and Tome (Windows)
        </Button>
        */}
      </section>
      <div style={{height: 50}}/>
    </div>
  )
}

function Progress() {
  if(isMobile) {
    return(
      <div class="elegant-color-dark z-depth-5">
        <div style={{height: 50}}/>
        <section className='info-container'>
          <h1 style={{color: "white"}}>Progress</h1>
          <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <div style={{width: '80vw'}}>
              <h3 style={{color: "white"}}>
                Syfte's Quest will be free, and is something I work on in addition to school and work. 
                With that in mind, I hope you can forgive the lack of a concrete timeline on an official release, 
                as well as whatever quality issues you may have with the game. Here is a rough progress timeline:
              </h3>
            </div>
          </div>
          <div style={{display:'flex', justifyContent:'left',alignItems: 'center'}}>
            <Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
            <h4 style={{color: "white", height:20}}>
              Basic gameplay, story
            </h4>
          </div>

          <div style={{display:'flex', justifyContent:'left',alignItems: 'center'}}>
            <Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
            <h4 style={{color: "white", height:20}}>
              Basic graphics
            </h4>
          </div>

          <div style={{display:'flex', justifyContent:'left',alignItems: 'center'}}>
            <Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
            <h4 style={{color: "white", height:20}}>
              Music, audio
            </h4>
          </div>

          <div style={{display:'flex', justifyContent:'left',alignItems: 'center'}}>
            <Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
            <h4 style={{color: "white", height:20}}>
              Polished map textures, portraits
            </h4>
          </div>

          <div style={{display:'flex', justifyContent:'left',alignItems: 'center'}}>
            <Checkbox value="checkedB" disabled unchecked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
            <h4 style={{color: "white", height:20}}>
              General polish
            </h4>
          </div>

          <div style={{display:'flex', justifyContent:'left',alignItems: 'center'}}>
            <Checkbox value="checkedB" disabled unchecked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
            <h4 style={{color: "white", height:20}}>
              Beta release (hopefully 2020 :))
            </h4>
          </div>
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <div style={{width: '80vw'}}>
              <h3 style={{color: "white"}}>
                As of now, the game is playable, with some bugs. However, the textures for each level 
                are the same (all walls are rocks, all tiles are snow, etc.), and portraits are simply 
                pixel art. In addition, there is very minimal audio, and no music in the game.
              </h3>
            </div>
          </div>
        </section>
        <div style={{height: 50}}/>
      </div>
    );
  }
  return(
    <div class="elegant-color-dark z-depth-5">
      <div style={{height: 50}}/>
			<section className='info-container'>
				<h1 style={{color: "white"}}>Progress</h1>
				<hr style={{color: "white", backgroundColor: "white", width: 100}}/>
				<div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <div style={{width: '50vw'}}>
          <h3 style={{color: "white"}}>
						Syfte's Quest will be free, and is something I work on in addition to school or work. 
						With that in mind, I hope you can forgive the lack of a concrete timeline on an official release, 
						as well as whatever quality issues you may have with the game. Here is a rough progress timeline:
          </h3>
        </div>
				</div>
				<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
					<Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
          <h4 style={{color: "white", height:20}}>
						Basic gameplay, story
          </h4>
				</div>

				<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
					<Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
          <h4 style={{color: "white", height:20}}>
						Basic graphics
          </h4>
				</div>

				<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
					<Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
          <h4 style={{color: "white", height:20}}>
						Music, audio
          </h4>
				</div>

				<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
					<Checkbox value="checkedB" checked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
          <h4 style={{color: "white", height:20}}>
						Polished map textures, portraits
          </h4>
				</div>

				<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
					<Checkbox value="checkedB" disabled unchecked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
          <h4 style={{color: "white", height:20}}>
						General polish
          </h4>
				</div>

				<div style={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
					<Checkbox value="checkedB" disabled unchecked inputProps={{ 'aria-label': 'primary checkbox', }} style={{height:75, width:75}}/>
          <h4 style={{color: "white", height:20}}>
						Beta release (hopefully 2020 :))
          </h4>
				</div>
				<div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
					<div style={{width: '50vw'}}>
						<h3 style={{color: "white"}}>
							As of now, the game is playable, with some bugs. However, the textures for each level 
							are the same (all walls are rocks, all tiles are snow, etc.), and portraits are simply 
							pixel art. In addition, there is very minimal audio, and no music in the game.
						</h3>
					</div>
        </div>
			</section>
			<div style={{height: 50}}/>
		</div>
	)
}

const Help = () => {
  if(isMobile) {
    return(
      <div class="elegant-color-dark z-depth-5">
        <div style={{height: 50}}/>
        <h1 style={{color: "white"}}>How can you help?</h1>
        <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
        <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{width:'80vw'}}>
          <h3 style={{color: "white", textAlign: 'center'}}>
            If you want to beta test the game when the time comes, email me.
            Also, if you happen to be an artist with a bunch of time on your hands (do those even exist?), please 
            contact me as well. 
          </h3>
          </div>
        </div>
        <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
        <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{width: '80vw'}}>
            <h5 style={{color: "white", textAlign: 'center'}}>
              Email: davintjong@gmail.com
              <br/>
              Github: <a href="https://github.com/dtjong"> github.com/dtjong</a>
            </h5>
          </div>
        </div>
        <div style={{height: 50}}/>
      </div>
    );
  }
  return(
    <div class="elegant-color-dark z-depth-5">
      <div style={{height: 50}}/>
      <section className='info-container'>
        <h1 style={{color: "white"}}>How can you help?</h1>
        <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
        <div style={{display:'flex', alignItems: 'center'}}>
          <div style={{width: '15vw'}}/>
          <div style={{width: '45vw'}}>
            <h3 style={{color: "white", textAlign: 'right'}}>
            If you want to beta test the game when the time comes, email me.
            Also, if you happen to be an artist with a bunch of time on your hands (do those even exist?), please 
            contact me as well. 
            </h3>
          </div>
          <div style={{width: 15}}/>
          <hr style={{color: "white", backgroundColor: "white", height: 150, width:1}}/>
          <div style={{width: 15}}/>
          <div style={{width: '25vw'}}>
            <h5 style={{color: "white", textAlign: 'left'}}>
              Email: davintjong@gmail.com
              <br/>
              Github: <a href="https://github.com/dtjong"> github.com/dtjong</a>
            </h5>
          </div>
          <div style={{width: '15vw'}}/>
        </div>
      </section>
      <div style={{height: 50}}/>
    </div>
  );
}

const Footer = (props) => {
  if(isMobile) {
    return(
	<div class="elegant-color-dark z-depth-5" style={{height: 70}}>
		<div style={{height: 15}}/>
		<section className='info-container'>
        <Grid container spacing={3} style={{width:'100vw'}}>
        <Grid item xs={4}>
			<a class="nav-link" style={{color: 'white', float: 'center'}} onClick={() => props.scroller()} href="#">
				Back to Top
			</a>
        </Grid>
        <Grid item xs={8}>
			<div style={{color:'white', textAlign: 'right', paddingTop: 6}}>
				Updated 1/29/20
			</div>
        </Grid>
      </Grid>
		</section>
	</div>
    );
  }
  return(
	<div class="elegant-color-dark z-depth-5" style={{height: 70}}>
		<div style={{height: 15}}/>
		<section className='info-container'>
<Grid container spacing={3} style={{width:'100vw'}}>
        <Grid item xs={4}>
			<div style={{color:'white', float: 'left', paddingLeft: 30, paddingTop: 6}}>
				Created by Davin Tjong
			</div>
        </Grid>
        <Grid item xs={4}>
			<a class="nav-link" style={{color: 'white', float: 'center'}} onClick={() => props.scroller()} href="#">
				Back to Top
			</a>
        </Grid>
        <Grid item xs={4}>
			<div style={{color:'white', textAlign: 'right', paddingTop: 6}}>
				Updated 1/21/20
			</div>
        </Grid>
      </Grid>
		</section>
	</div>
  );
}

const About = () => {
  if(isMobile) {
    return(
      <div class="elegant-color-dark z-depth-5">
        <div style={{height: 50}}/>
        <h1 style={{color: "white"}}>About</h1>
        <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <div style={{width: '80%'}}>
            <h3 style={{color: "white"}}>
              Syfte's Quest is a turn-based strategy game with a lighthearted story.
              Follow Sten, a dying old man, on a journey to survive a post-apocalyptic wasteland. 
              Meet friends, fight off monsters, and struggle to survive.
            </h3>
          </div>
        </div>
        <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <div style={{width: '80vw'}}>
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
              <div>
                <img src={screenshot1}/>
              </div>
              <div>
                <img src={screenshot2}/>
              </div>
              <div>
                <img src={screenshot5}/>
              </div>
            </Carousel>
          </div>
          </div>
        <div style={{height: 50}}/>
      </div>
    );
  }

  return(
    <div class="elegant-color-dark z-depth-5">
      <div style={{height: 50}}/>
      <h1 style={{color: "white"}}>About</h1>
      <hr style={{color: "white", backgroundColor: "white", width: 100}}/>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <div style={{width: '15vw'}}/>
        <div style={{width: '50vw'}}>
          <h3 style={{color: "white", textAlign:'right'}}>
            Syfte's Quest is a turn-based strategy game with a lighthearted story.
            Follow Sten, a dying old man, on a journey to survive a post-apocalyptic wasteland. 
            Meet friends, fight off monsters, and struggle to survive.
          </h3>
        </div>

        <div style={{width: '5vw'}}/>

        <div style={{width:550}}>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
            <div>
              <img src={screenshot1}/>
            </div>
            <div>
              <img src={screenshot2}/>
            </div>
            <div>
              <img src={screenshot5}/>
            </div>
          </Carousel>
        </div>
        <div style={{width: '15vw'}}/>
      </div>
      <div style={{height: 50}}/>
    </div>
  );
}

const BG = (props) => (
	<Parallax bgImage={props.bg} strength={500} renderLayer={percentage => (
		<div>
			<div
			style={{
				position: "absolute",
					background: `rgba(0, 0, 0, ${(1 - percentage * 1.5)})`,
					left: "50%",
					top: "50%",
					borderRadius: "50%",
					transform: "translate(-50%,-50%)",
					width: 10000,
					height: 10000
			}}
			/>
		</div>
	)} >
		<div style={{height: props.height}}>
		</div>
	</Parallax>
);

