import React, { Component } from 'react'
import epcinoplogo from '../images/Epcinop.png'
import {isMobile} from 'react-device-detect';

export default class Navbar extends Component {
  render() {
    if(isMobile) {
      return (
        <div>
          <nav class="navbar fixed-top elegant-color-dark navbar-dark navbar-expand-lg primary-color">
            <a class="navbar-brand" href="#">
              <img src={epcinoplogo} height="30" class="d-inline-block align-top"
                alt="EPCINOP">
              </img>
            </a>
            <div id="navbarNav">
              <ul class="navbar-nav">
                <li class=" nav-item">
                  <a class="nav-link" href="#b&t" onClick = {this.props.showgame}>
                    Syfte's Quest
                  </a>
                </li>
              </ul>
            </div>
            <div id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="#DavinTjong" onClick = {this.props.showprof}>About Me</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      );
    }
    return (
      <div>
        <nav class="navbar fixed-top elegant-color-dark navbar-dark navbar-expand-lg primary-color">
          <a class="navbar-brand" href="#">
            <img src={epcinoplogo} height="30" class="d-inline-block align-top"
              alt="EPCINOP">
            </img>
          </a>
          <div class="navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class=" nav-item waves-effect">
                <a class="nav-link" href="#B&T" onClick = {this.props.showgame}>
                  Syfte's Quest
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#DavinTjong" onClick = {this.props.showprof}>Recruiters Look Here!</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
