import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar.jsx'
import Gamepage from './components/gamepage.jsx'
import Profpage from './components/profpage.jsx'

class App extends React.Component{
  constructor(props) {
    super(props);

    document.title = 'Syfte\'s Quest'
    this.state = {gamepage: true};
    this.showgame = this.showgame.bind(this);
    this.showprof = this.showprof.bind(this);

    if(window.location.href.split('#')[1] == 'B&T') {
      this.setState({gamepage: true});
    } else {
      this.setState({gamepage: false});
    }
  }

  showgame(page) {
    document.title = 'Syfte\'s Quest'
    this.setState({gamepage: true});
  }

  showprof(page) {
    document.title = 'Davin Tjong'
    this.setState({gamepage: false});
  }

  render() {
    let text;

    if(this.state.gamepage) {
      text = <Gamepage></Gamepage>
    } else {
      text = <Profpage></Profpage>
    }

    return (
      <div className="App">
        <Navbar showgame = {this.showgame} showprof = {this.showprof}></Navbar>
      {text}
      </div>
    );
  }
}

export default App;
